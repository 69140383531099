<template>
  <Body
    class="bg-white text-gray-800 antialiased font-poppins default"
    :class="{ 'overflow-hidden ': layout.open || layout.login }"
  >
    <div class="flex flex-col min-h-screen">
      <FrontpageHeader />

      <main class="flex-1">
        <slot name="default">
          <div class="h-24" />
        </slot>
      </main>

      <FrontpageFooter />

      <div class="fixed right-8 bottom-5 grid gap-6 max-lg:hidden z-20">
        <div class="border rounded-br-xl border-gray-300 w-fit">
          <FrontpageButton
            class="duration-200 translate-y-2 -translate-x-2 hover:translate-x-0 hover:translate-y-0 !px-4 !py-2"
            content-class="flex-col justify-start !items-start !gap-0 normal-case"
            @click="handleFinderClick"
          >
            <span class="font-normal">
              {{ altFinderConfig?.label1 ?? "Firmenwagen-" }}
            </span>
            {{ altFinderConfig?.label2 ?? "Finder" }}
          </FrontpageButton>
        </div>

        <div class="border rounded-br-xl border-gray-300 w-fit">
          <FrontpageButton
            class="duration-200 translate-y-2 -translate-x-2 hover:translate-x-0 hover:translate-y-0 !px-4 !py-1.5"
            content-class="flex-col justify-start !items-start !gap-0 normal-case"
            @click="layout.scout = true"
          >
            <span class="font-normal">Service-Scout</span>
            Tim
          </FrontpageButton>
        </div>
      </div>

      <Modal v-if="!account" v-model="layout.login">
        <FrontpageLogin />
      </Modal>

      <Modal v-if="!account" v-model="layout.authGate">
        <FrontpageAuthGate />
      </Modal>

      <Modal v-if="!account" v-model="layout.forgotPassword">
        <FrontpageForgotPasswordModal />
      </Modal>

      <Modal v-model="layout.finder" class="!p-0">
        <div
          class="flex-1 md:rounded-lg overflow-hidden shadow-lg m-auto max-w-screen-xl shadow-black/5 flex justify-center items-center w-full"
        >
          <FrontpageVehicleFinderX
            container-class="w-full px-4"
            popup
            class="w-full p-4 md:p-6"
            @update="layout.finder = false"
          />
        </div>
      </Modal>
      <Modal v-model="layout.scout">
        <div
          class="grow-0 md:rounded-lg overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-md w-full max-md:bg-white max-md:absolute max-md:inset-0"
        >
          <FrontpageContactBox class="m-auto" popup />
        </div>
      </Modal>

      <Modal v-model="layout.otkInsight">
        <FrontpageModalNewsletter class="m-auto" />
      </Modal>
    </div>
    <Consent />
  </Body>
</template>

<script setup>
const { account, layout } = useStore();
const route = useRoute();
const router = useRouter();

const altFinderConfig = computed(() => {
  if (route.path.startsWith("/exclusive")) {
    return {
      label1: "Zum",
      label2: "Abrufschein",
      link: "/autohaus",
    };
  }

  if (route.path.startsWith("/insights")) {
    return {
      label1: "Zum",
      label2: "Abrufschein",
      link: "/abrufschein",
    };
  }

  return null;
});

function handleFinderClick() {
  if (route.path.startsWith("/exclusive")) {
    router.push("/autohaus");
    return;
  }

  if (route.path.startsWith("/insights")) {
    router.push("/abrufschein");
    return;
  }

  layout.value.finder = true;
}

useHead({
  htmlAttrs: {
    lang: "de",
  },
});
</script>

<style>
@import url("~/assets/styles/tailwind.css");
@import url("~/assets/fonts/poppins/fontface.css");
</style>
